import { PATH_REPORTS  } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../components/Auth/AuthProtect';
import DashboardLayout from '../layouts/DashboardLayout';

export const ReportsRoutes = {
  path: PATH_REPORTS.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_REPORTS.reports.all_reports,
      component: lazy(() => import('../views/pages/Reports/Main'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.report_detail,
      component: lazy(() => import('../views/pages/Reports/Detail'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_by_vuln,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_vuln'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_by_bug,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_bug'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_coverage,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_coverage'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_eox,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_eox'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_ldos,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_ldos'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_eos,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_eos'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_by_bug_severity,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_bug_severity'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_by_vuln_severity,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_vuln_severity'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_by_type,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_type'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_ss,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_by_ss'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_issues_by_closed_status,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_issues_by_closed_status'))
    },
    {
      exact: true,
      path: PATH_REPORTS.reports.asset_issues_by_status,
      component: lazy(() => import('../views/pages/Reports/Impl/asset_issues_by_status'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

