import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  currentIssue: undefined,
  notifications: null,
  issueList: [],
  query: '',
  idSelected: -1,
  status: 'all',
  asset: undefined,
  type: 'all',
  total: 0,
  timePeriod: 'all'
};

const slice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },

    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    // SET SAVED ASSET
    setIssue(state, action) {
      state.isLoading = false;
      state.currentIssue = action.payload;
    },

    setAsset(state, action) {
      state.isLoading = false;
      state.asset = action.payload;
    },

    setStatus(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },

    setType(state, action) {
      state.isLoading = false;
      state.type = action.payload;
    },

    setTimePeriod(state, action) {
      state.isLoading = false;
      state.timePeriod = action.payload;
    },

    setMine(state, action) {
      state.mine = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    },

    setIssueList(state, action) {
      state.isLoading = false;
      state.issueList = action.payload.data;
      state.total = action.payload.total;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    onChangeStatus(state, action) {
      state.status = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  setIdSelected,
  setIssue,
  setAsset,
  setMine,
  setQuery,
  setStatus,
  setType,
  setTimePeriod
} = slice.actions;

// DEFAULTS
const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';


export function deleteIssue(issueId, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/issue/${issueId}`);
      return true
      // dispatch(slice.actions.onChangeStatus('deleted'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function saveIssue(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response
      await axios.post(`/api/issue`, body);
      callOnSubmitted(true);
    } catch (error) {
      callOnSubmitted(false);
    }
  };
}

export function getIssueById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: handle the response
      const response = await axios.get(`/api/issue/${id}`);
      dispatch(setIssue(response.data));
    } catch (error) {
      errorDialog.msg = `Error finding non compliant issue ${id}`;
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function changeStatus(data, action, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/issue/apply/${action}/${data.id}`, data);
      dispatch(slice.actions.setIssue(undefined));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function updateIssue(data, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/issue/${data.id}`, data);
      dispatch(slice.actions.onChangeStatus('updated'));
      dispatch(slice.actions.setIssue(undefined));
      callOnSubmitted(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callOnSubmitted(false);
    }
  };
}

export function getIssuesList(
  query,
  asset_id,
  page,
  size,
  dir,
  orderBy,
  timePeriod,
  status,
  type,
  supertype
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const p = {};

    if (page) p.page = page;
    if (asset_id) p.asset_id = asset_id;
    if (size) p.size = size;
    if (dir) p.dir = dir;
    if (orderBy) p.order_by = orderBy;
    if (status !== 'all') p.status = status;
    if (timePeriod) p.time_period = timePeriod;
    if (type !== 'all') p.type = type;
    if (supertype !== 'all') p.supertype = supertype;

    try {
      const response = await axios.get(
        `/api/issues/${query ? query : '_all_'}`,
        { params: p }
      );
      dispatch(slice.actions.setIssueList(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
    dispatch(slice.actions.stopLoading());
  };
}
