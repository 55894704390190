/* eslint-disable prettier/prettier */
import NProgress from 'nprogress';
import AppRoutes from './AppRoutes';
import { PATH_GENERAL, PATH_PAGE } from './paths';
import LoadingScreen from '../components/LoadingScreen';
import GuestProtect from '../components/Auth/GuestProtect';
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InventoryRoutes from './InventoryRoutes';
import GeneralRoutes from './GeneralRoutes';
import ComplianceRoutes from './ComplianceRoutes';
import AuditRoutes from './AuditRoutes';
import RemediationRoutes from './RemediationRoutes';
import NotificationRoutes from './NotificationRoutes';
import TasksRoutes from './TasksRoutes';
import { useHistory } from 'react-router';
import AuthProtect from '../components/Auth/AuthProtect';
import { ReportsRoutes } from './ReportsRoutes';


const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

const DebugRouter = ({ children }) => {
  const { location } = useHistory();
  console.log(
    `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
      location
    )}`
  );
  return children;
};

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
  {/*     <DebugRouter> */}
        <Switch>
          {routes.map((route, i) => {
            const Component = route.component;
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;

            return (
              <RouteProgress
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
{/*       </DebugRouter> */}
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import('../views/auth/LoginView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.logout,
    component: lazy(() => import('../views/auth/LogoutView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginUnprotected,
    component: lazy(() => import('../views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.register,
    component: lazy(() => import('../views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: AuthProtect,
    path: PATH_PAGE.callback,
    component: lazy(() => import('../views/auth/CallbackView'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('../views/errors/Page404View'))
  },
  {
    exact: true,
    path: '/500',
    component: lazy(() => import('../views/errors/Page500View'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to={PATH_PAGE.auth.root} />
  },

  // App Routes
  AppRoutes,

  // RemediationRoutes Routes
  RemediationRoutes,

  // RemediationRoutes Routes
  ReportsRoutes,

  // Discovery Routes
  InventoryRoutes,

  //General Routes
  GeneralRoutes,

  //Compliance Routes
  ComplianceRoutes,

  //Audit Routes
  AuditRoutes,

  //Notification Routes
  NotificationRoutes,

  //Tasks Routes
  TasksRoutes,
  
];

export default routes;
