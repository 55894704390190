import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  status: '',
  notifications: null,
  ntaskList: [],
  total: 0,
  currentNTask: {},
  idSelected: -1,
  isConfirming: false,
  confirmedStatus: true
};

const slice = createSlice({
  name: 'ntask',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },

    // RESET ERROR
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    setNTaskList(state, action) {
      state.isLoading = false;
      state.ntaskList = action.payload;
    },

    setNTask(state, action) {
      state.isLoading = false;
      state.currentNTask = action.payload;
    },

    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    onChangeStatus(state, action) {
      state.status = action.payload;
    },
    setConfirmed(state, action) {
      state.confirmedStatus = action.payload;
    },
    setIsConfirming(state, action) {
      state.isConfirming = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetError, startLoading, setNTask, setNTaskList } = slice.actions;

// DEFAULTS

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'Error';
errorDialog.acceptBtnMsg = 'Close';

export function getNTasks(query, page, size, order, orderBy, status, type) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (page) p.page = page;
      if (size) p.size = size;
      if (order) p.order = order;
      if (orderBy) p.orderBy = orderBy;
      if (status) p.status = status;
      if (type) p.type = type;
      
      const response = await axios.get(`/api/tasks/search/${query ? query : '_all_'}`, {
        params: p
      });
      dispatch(slice.actions.setNTaskList(response.data.data));
      dispatch(slice.actions.setTotal(response.data.total));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function stopTask(id) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/tasks/${id}/stop`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function confirmTaskById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setIsConfirming(true));
      const response = await axios.post(`/api/task/${id}/confirm`);
      dispatch(slice.actions.setConfirmed(response.status == 200));
    } catch (error) {
      dispatch(slice.actions.setConfirmed(false));
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.setIsConfirming(false));
  };
}


export function getnTasksListByTemplateId(templateId, limit, order, orderBy,) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let p = {};
      if (limit) p.limit = limit;
      if (order) p.order = order;
      if (orderBy) p.orderBy = orderBy;

      const response = await axios.get(`/api/tasks/template/${templateId}`, {
        params: p
      });
      dispatch(slice.actions.setNTaskList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNTasksById(id) {
  if (id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/task/${id}`);
        dispatch(slice.actions.setNTask(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
      dispatch(slice.actions.stopLoading());
    };
  } else {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.setNTask({}));
        dispatch(slice.actions.stopLoading());
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
}
