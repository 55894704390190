import axios, {api2} from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { startDialog, closeDialog, ErrorDialog } from './errorDialog';

const initialState = {
  isLoading: false,
  error: false,
  errorDetails: {},
  status: '',
  currentTemplate: undefined,
  notifications: null,
  templatesList: [],
  // a map of templates indexed by id.
  templatesMap: {},
  idSelected: -1,
  asset: undefined,
  testResult: {status: null},
  isTesting: false,
  tags: [],
  type: 'all',
  total: 0,
  query: '',
  alert: undefined,
  testingTaskId: undefined,
};
//templatesList, idSelected, query, type, total

const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorDetail = action.payload;
    },
    resetError(state, action) {
      state.isLoading = false;
      state.error = false;
      state.errorDetail = {};
    },

    setTemplate(state, action) {
      // I only add the template to the templatesMap
      const template = action.payload;
      state.currentTemplate = template;
    },

    setTemplateList(state, action) {
      state.isLoading = false;
      state.templatesList = action.payload;
    },

    setTemplateListAndCount(state, action) {
      state.isLoading = false;
      state.templatesList = action.payload.data;
      state.total = action.payload.total;
    },

    // TODO: check if this is needed.
    setIdSelected(state, action) {
      state.isLoading = false;
      state.idSelected = action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    onChangeStatus(state, action) {
      state.status = action.payload;
    },
    setAsset(state, action) {
      state.asset = action.payload;
    },
    setTestResult(state, action) {
      state.testResult = action.payload;
    },
    setIsTesting(state, action) {
      state.isTesting = action.payload;
    },
    setTags(state, action) {
      state.tags = action.payload;
    },
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setType(state, action) {
      state.isLoading = false;
      state.type = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setTestingTaskId(state, action) {
      state.testingTaskId = action.payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  startLoading,
  setIdSelected,
  setTemplate,
  setTemplateList,
  setAsset,
  setIsTesting,
  setType,
  setQuery,
  setTags,
  setAlert,
} = slice.actions;

// DEFAULTS

const errorDialog = new ErrorDialog();
errorDialog.title = 'Error';
errorDialog.msg = 'There was a problem with your request. Please try again.';
errorDialog.acceptBtnMsg = 'Close';

export function getTemplatesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/templates`);
      dispatch(slice.actions.setTemplateList(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function getTemplate(templateId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/template/${templateId}`);
      if (response.data.data) {
        dispatch(slice.actions.setTemplate(response.data.data));
        return response.data.data
      } else {
        dispatch(slice.actions.setTemplate([]));
      }
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function saveTemplate(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/template`, body);
      await dispatch(getTemplate(response.data.data.id));
      callOnSubmitted(true);
      return response
    } catch (error) {
      callOnSubmitted(false);
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error saving template';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function updateTemplate(body, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/api/template/${body.id}`, body);
      dispatch(slice.actions.stopLoading());
      callOnSubmitted(true);
    } catch (error) {
      callOnSubmitted(false);
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error patching template';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function testTemplate(body) {
  return async (dispatch) => {
    
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setIsTesting(true));
    try {
      body.dryrun = body.is_check;
      body.debug = body.is_debug;
      body.check = body.is_diff;
      const response = await api2.post(`/api/v1/tasks/test`, body);
      dispatch(slice.actions.setTestingTaskId(response.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.setIsTesting(false));
      dispatch(slice.actions.stopLoading());
      errorDialog.msg = 'Error testing template';
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}

export function deleteTemplate(id, callOnSubmitted) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/template/${id}`);
      dispatch(getTemplatesList());
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());

      errorDialog.title = 'Error deleting a credential';
      if (
        error?.response?.data?.message.indexOf('FOREIGN KEY (`templateId`)') !==
        -1
      ) {
        errorDialog.msg =
          'There are issues using this template. Please delete them first.';
      } else {
        errorDialog.title =
          'error deleting a credential, contact the administrator';
      }
      errorDialog.acceptBtnClickFn = () => {
        dispatch(closeDialog());
      };

      dispatch(startDialog(errorDialog));
    }
  };
}

export function searchTemplateLists(
  query,
  page,
  size,
  dir,
  orderBy,
  type
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const p = {};

    if (page) p.page = page;
    if (size) p.size = size;
    if (dir) p.dir = dir;
    if (orderBy) p.order_by = orderBy;
    if (type !== 'all') p.type = type;

    try {
      const response = await axios.get(
        `/api/search_templates/${query ? query : '_all_'}`,
        { params: p }
      );
      dispatch(slice.actions.setTemplateListAndCount(response.data));
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
    dispatch(slice.actions.stopLoading());
  };
}


export function searchTemplatesByName(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/templates/searchname/${name ? name : '_all_'}`
      );
      dispatch(slice.actions.setTemplateList(response.data.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      errorDialog.acceptBtnClickFn = (evt) => {
        dispatch(closeDialog());
      };
      dispatch(startDialog(errorDialog));
    }
  };
}
